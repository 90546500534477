
// import Swiper JS
import Swiper, { Navigation, Autoplay } from 'swiper'

// import Swiper styles
import 'swiper/css'

//custom components
import mouseWheel from '@/components/mouseWeel.vue'

//particles
(window as any).particlesJS.load('particles-js', '/js/particles.json', function () {
  console.log('callback - particles.js config loaded')
})

//axios
import axios from 'axios';

import AOS from 'aos'
import 'aos/dist/aos.css'

//init vue component
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  data() {
    return {
      scroll: false,
      services: [] as any[any],
      clients: [] as any[any],
    }
  },
  components: {
    mouseWheel
  },
  async created() {
    //get services
    const services = await axios.get('http://admin.4sillas.adro.studio/api/items/services?fields=*,image.*')
    this.services = services.data.data

    //get clients
    const clients = await axios.get('http://admin.4sillas.adro.studio/api/items/clients?fields=*,logo.*')
    this.clients = clients.data.data

    this.$nextTick(() => {
      this.createSwiper()
      this.createServicesSwiper()
      AOS.init()
    })
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.onScroll()
    })
  },
  methods: {
    onScroll() {
      this.scroll = (window.pageYOffset > 0) ? true : false
    },
    createSwiper() {
      //create banners swiper
      const swiperOpts = {
        slidesPerView: 3,
        spaceBetween: 8,
        speed: 500,
        loop: true,
        autoplay: {
          delay: 3000,
        },
        breakpoints: {
          768: {
            slidesPerView: 6,
          }
        },
        modules: [Autoplay]
      }

      new Swiper(this.$refs.clients as any, swiperOpts)
    },
    createServicesSwiper() {
      //create banners swiper
      const swiperOpts = {
        slidesPerView: 2,
        spaceBetween: 8,
        breakpoints: {
          768: {
            slidesPerView: 4,
          }
        }
      }

      new Swiper(this.$refs.services as any, swiperOpts)
    }
  }
});
